<style type="text/css">
@import "~@/assets/css/common.css";

.form-control {
  padding: unset;
}
.el-form-item {
  margin-bottom: unset;
  flex: 1;
}
.check-img {
  height: 30px;
}
.form-control {
    padding: unset;
    border: unset;
    background: #fffbf2;
    height: unset;
  }

</style>
<style type="text/css" scoped>
/deep/.el-input__inner {
  background-color: #fff9eb;
  width: 100%;
  height: 35px;
}

/deep/ .el-textarea__inner {
  background-color: #fff9eb;
}

/deep/.el-form-item__content {
  line-height: inherit;
}

.el-form-item--small .el-form-item__error {
  margin-top: 2px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 100%;
  text-align: center;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getcode } from "@/api/index.js";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    const checkIdcard = (rule, value, cb) => {
      const regIdcard =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (regIdcard.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的身份证号"));
    };
    return {
      title: "成绩查询列表",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名记录",
          active: true,
        },
      ],
      defaultvalue: 3,
      currentpage: 3,
      testSid: "",//考试Sid
      formData: {
        xm: "",
        sfzjh: "",
        code: "",
        uuid: "",
      },
      imgCode: {
        uuid: "",
        img: "",
        code: "",
      },
      rules: {
        xm: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "change",
          },
        ],
        sfzjh: [
          {
            required: true,
            message: "请输入身份证件号",
            trigger: "change",
          },
          {
            validator: checkIdcard,
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    // 获取图片验证码
    getImageCode: function () {
      var _this = this;
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode = res.data;
          _this.formData.uuid = res.data.uuid;
        }
      });
    },
    submit() {
      console.log(this.formData);
      this.$refs.form.validate((valid) => {
        if (valid) {

          //跳转成绩详情
          this.$router.push({
          path: "/scoreList",
          query: {
            sid: this.testSid,
            sfzjh: this.formData.sfzjh,
          },
        });
          return false;
        }
        console.log(valid);
        
      });
    },
  },
  mounted() {
    this.getImageCode();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2"
                >考试名称：2021年四川省住房和城乡建设厅公开考调事业单位工作人员</span
              >
            </h4>
            <div class="col-sm-3 mt-4">
              <el-form ref="form" :model="formData" :rules="rules">
                <div class="input-group mb-2 flexList">
                  <label for="Username" class="mr-2 user-label">考生姓名</label>
                  <el-form-item prop="xm">
                    <el-input
                      id="Username"
                      type="text"
                      class="form-control"
                      v-model="formData.xm"
                    />
                  </el-form-item>
                </div>
                <div class="input-group mb-2 flexList">
                  <label for="idCard" class="mr-2 user-label">身份证件号</label>
                  <el-form-item prop="sfzjh">
                    <el-input
                      id="idCard"
                      type="text"
                      class="form-control"
                      v-model="formData.sfzjh"
                    />
                  </el-form-item>
                </div>
                <div class="input-group mb-2 flexList">
                  <label for="condition" class="mr-2 user-label">验证码</label>
                  <el-form-item class="w-50" prop="code">
                    <el-input
                      id="condition"
                      type="text"
                      class="form-control"
                      v-model="formData.code"
                    />
                  </el-form-item>
                  <img
                    :src="imgCode.img"
                    class="check-img"
                    @click="getImageCode"
                  />
                </div>
                <div class="input-group mb-2 flexList">
                  <label for="condition" class="mr-2 user-label"></label>
                  <div class="h35">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="width: 255px"
                      @click="submit"
                    >
                      查询成绩
                    </button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
